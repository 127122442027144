  .soil-analysis {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 15px;
    width: 100%;
    overflow-x:auto;
  }
  .soil-analysis::-webkit-scrollbar {
    display: none;
  }
  .soil-analysis-heading{
    display: flex;
    font-weight: bold;
    padding: 10px 5px;
  }
  .soil-analysis-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 0;
  }
  
  .label-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-right: 10px;
    font-size: 16px;
  }
  
  .progress-bar {
    width: 120px;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
  }
 
  .progress {
     height: 10px; /* Set height for the progress bar */
     border-radius: 4px;
     position: absolute;
     top: 0;
     left: 0;
     background-color: #4caf50; /* Default color */
     color: transparent; /* Hide text if you don't need it */
     white-space: nowrap; /* Prevent text overflow */
     text-align: center;
 
  }
  
  .alert-value {
    position: absolute;
    right: 0;
    top: -20px;
    background-color: red;
    color: white;
    padding: 3px;
    border-radius: 5px;
  }
  
  .value-box {
    text-align: right;
    font-size: 12px;
  }
  
  .secondary-value {
    font-size: 10px;
    color: #888;
  }
  @media only screen and (max-width: 1024px){
    .soil-analysis {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: white;
      padding: 15px;
      width: 100%;
      overflow-x:auto;
    }
    .soil-analysis::-webkit-scrollbar {
      display: none;
    }
    .soil-analysis-heading{
      display: flex;
      font-weight: bold;
      padding: 10px 5px;
    }
    .soil-analysis-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      margin: 10px 0;
    }
    
    .label-circle {
      width: 110px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      margin-right: 10px;
      font-size: 11px;
    }
    
    .progress-bar {
      width: 120px;
      height: 10px;
      background-color: #e0e0e0;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      margin-right: 10px;
    }
   
    .progress {
       height: 10px; /* Set height for the progress bar */
       border-radius: 4px;
       position: absolute;
       top: 0;
       left: 0;
       background-color: #4caf50; /* Default color */
       color: transparent; /* Hide text if you don't need it */
       white-space: nowrap; /* Prevent text overflow */
       text-align: center;
   
    }
    
    .alert-value {
      position: absolute;
      right: 0;
      top: -20px;
      background-color: red;
      color: white;
      padding: 3px;
      border-radius: 5px;
    }
    
    .value-box {
      text-align: right;
      font-size: 12px;
    }
    
    .secondary-value {
      color: #888;
    }
  }
  @media only screen and (max-width: 600px){
    .soil-analysis {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: white;
      padding: 15px;
      width: 100%;
      overflow-x:auto;
    }
    .soil-analysis::-webkit-scrollbar {
      display: none;
    }
    .soil-analysis-heading{
      display: flex;
      font-weight: bold;
      padding: 10px 5px;
    }
    .soil-analysis-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      margin: 10px 0;
    }
    
    .label-circle {
      width: 34px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      margin-right: 10px;
      font-size: 16px;
    }
    
    .progress-bar {
      width: 120px;
      height: 10px;
      background-color: #e0e0e0;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      margin-right: 10px;
    }
   
    .progress {
       height: 10px; /* Set height for the progress bar */
       border-radius: 4px;
       position: absolute;
       top: 0;
       left: 0;
       background-color: #4caf50; /* Default color */
       color: transparent; /* Hide text if you don't need it */
       white-space: nowrap; /* Prevent text overflow */
       text-align: center;
   
    }
    
    .alert-value {
      position: absolute;
      right: 0;
      top: -20px;
      background-color: red;
      color: white;
      padding: 3px;
      border-radius: 5px;
    }
    
    .value-box {
      text-align: right;
      font-size: 12px;
    }
    
    .secondary-value {
      color: #888;
    }
  }
  