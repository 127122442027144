
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
}

.container_signup {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  overflow: hidden;
}

/* Left Panel Styling */


/* Right Panel Styling */

.right-panel-container-signup{
  width:55%;
  padding: 30px 40px;
  align-items: center;
  background-color: #f2f2f2;
}

.header-row-signup{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.logo-container-signup {
  width: 50%;
}

.signup-container-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.social-signup-mobile{
  display: none;
}

.password-validation{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.login-link-signup {
  color: #28a745;
  text-decoration: none;
  font-weight: bold;
}


.signupbtn {
  background-color: #28a745;
  color: white;
  padding: 12px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.or-continue-with-signup-mobile{
  display: none;
}
.login-link-container-mobile{
  display: none;
}


/* Responsive for Tablet */




/* Responsive for Mobile */
@media only screen and (max-width: 576px) {
  .container_signup {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: hidden;
  }
  
  .header-row-signup{
    display: none;
  }

  .login-link-container-mobile, .or-continue-with-signup-mobile {
    display: flex;
  }

  .right-panel-container-signup{
    width:100%;
  }
}
