.right-container {
    height: 100%;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.upper-half {
    display: flex;
    flex-direction: column;
    border-bottom: 2px dashed grey;
    align-items: center;
    padding-bottom: 1vh;
    margin-bottom: 1vh;
}
.upper-half-1{
    display: flex;
    flex-direction: row;
    width:100%;
    margin-bottom: 10px;
    justify-content: space-between;
}
.upper-half-season{
    display: flex;
    width: 50%;
    justify-content: space-between;
}
.upper-half-cropname{
    display: flex;
    align-items: center;
    font-size: 12px;
    background-color: #EEEEEE;
    color: #4B4B4B;
    padding: 5px;
}

.lower-half {
    flex: 2;
    /* flex-grow: 2.5; */
    /* background-color:blue; */
}

.upper-half-month-dur {
    padding: 5px;
    border: 0px solid grey;
    border-radius: 5px;
    background-color: #EEEEEE;
    color: #4B4B4B;
    font-size: 12px;
}


.ga-btn {
    padding: 3px;
    border: 1px solid #34C759;
    background-color: transparent;
    color: #4B4B4B;
    font-weight: 500;
    border-radius: 5px;
}

.ga-btn-active {
    padding: 3px;
    padding-left: 1vw;
    padding-right: 1vw;
    background-color: #34C759;
    border: 0;
    border-radius: 5px;
    color: white;
    font-weight: 500;
}

.forecast-card-CS {
    font-size: 0.8vw;
    align-items: center;
}

.iconCS {
    font-size: 1.5vw;
    color: orange;
}

.line {
    height: 0.2vh;
    width: 3.5vw;
    background-color: #34C759;
    margin-top: 2vw;
}


.lower-half-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.column_cropstages {
    display: flex;
    flex-direction: column;
    height: 100%;
}


.js-se {
    justify-content: space-evenly;
}

.aic {
    align-items: center;
}

.wfc {
    width: fit-content;
}

.hfc {
    height: fit-content;
}

.vert-scroll {
    overflow: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.vert-scroll::-webkit-scrollbar {
    display: none;
  }

.pos-abs {
    position: absolute;
}

.fs-small {
    width: 2vw;
    height: 2vw;
}


