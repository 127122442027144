body {
  font-family: Arial, sans-serif;
}
.Soilreport-Container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  min-height: 100vh;
  background-color: #F0F2F5;
}
.Soilreport-content-1{
  display: flex;
  width: 5%;
}
.Soilreport-content-2 {
  width: 95%;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 44px;
}
.Soilreport-top{
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.Soilreport-heading{
  font-weight: bold;
  padding-Bottom: 10px;
  font-size: 18px;
}
.Soilreport-para{
  color: var(--Neutral-600, #4B4B4B);
  text-align: justify;
}
.Soilreport-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40vw;
}
.Soilreport-funnel {
  display: flex;
  flex-direction: row;
  width: 108px;
  height: 40px;
  padding: 12px 10px;

  gap: 22px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  margin-left: 1.2vw;
  align-items: center;
}
.Funnel {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.Soilreport-main-section {
  display: flex;
  flex-direction: column;
  margin-top: 1.2vw;
}

.Soilreport-date {
  display: flex;
  width: 108px;
  height: 40px;
  padding: 12px 10px;
  flex-direction: row;
  
  gap: 22px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-items: center;
}
.Soilreport-time {
  display: flex;
  width: 108px;
  height: 40px;
  padding: 12px 10px;
  flex-direction: row;
  gap: 22px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-items: center;
}

.Soilreport-TableHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 1250px; */
  width: 87.10vw;
  /* height: 70px; */
  height: 9.07vh;
  padding: 0px 20px;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  border: 1px solid #979797;
  background: var(--Neutral-100, #f5f5f5);
  margin-left: 1.2vw;
}
.Soilreport-TableContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 1.2vw;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #e1cfff;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* width: 1250px;
  height: 70px; */
  width: 87.10vw;
  /* height: 70px; */
  height: 9.07vh;


  padding: 0px 20px;
  align-items: center;
  gap: 20px;
  
}
.Soilreport-download{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;

}
.Soilreport-download-p{
    margin: 0;
    color: #36518F;
}
.Soilreport-funnel-p{
    margin: 0;
}
.Soilreport-date-p{
    margin: 0;
}
.Soilreport-time-p{
    margin: 0;
}

.pagination{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
}
.pagination-prev{
  padding: 10px;
  margin-right: 10px;
  background: #34c759;
  color: white;
  border-radius: 10px;
}
.pagination-next{
  padding: 10px;
  margin-left: 10px;
  background: #34c759;
  color: white;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .Soilreport-Container {
    flex-direction: column;
  }
  .Soilreport-content-1{
    display: none;
  }
  .Soilreport-content-2 {
    width: 100%;
  }
  .Soilreport-main-section{
    
    width: 100%;
  }
  .Soilreport-main-section-1{
    overflow-y: auto;
  }
  
.Soilreport-TableHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 1250px; */
  width: 143.10vw;
  /* height: 70px; */
  height: 10.07vh;
  padding: 0px 20px;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  border: 1px solid #979797;
  background: var(--Neutral-100, #f5f5f5);
  margin-left: 1.2vw;
}
.Soilreport-TableContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 1.2vw;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #e1cfff;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* width: 1250px;
  height: 70px; */
  width: 143.10vw;
  /* height: 70px; */
  height: 10.07vh;


  padding: 0px 20px;
  align-items: center;
  gap: 20px;
  
}
}