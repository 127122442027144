  .input-group {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .input-group input {
    width: 100%;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    height: 60px;
  }