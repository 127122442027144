body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}
.EditProfile-Container {
  display: flex;
  min-height: 100vh;
  background-color: #f0f2f5;
}
.EditProfile-Container1 {
  display: flex;
  min-height:-webkit-fill-available;
  width: 5%;
}
.EditProfile-Container2 {
  width:95%;
  padding: 20px;
  box-sizing: border-box;
  background: var(--Background-Base, #f0f2f5);
}
.EditProfile-maincontent {
  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-left: 50px;
  border-radius: 0.5rem;
  background: #ececec;
  padding: 20px 60px 40px 60px;
}
.EditProfile-maincontent-top {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%;
}
.Edit-top-second-half{
  width:60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.EditProfile-Image {
  display: flex;
  flex-direction: column;
}

.avatar-wrapper {
  position: relative;
  width: 100px; /* Set your preferred width */
  height: 100px;
}


.avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Circular image */
  object-fit: cover; /* Ensures the image fits well within the circle */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for better look */
}

.edit-badge {
  position: absolute;
  bottom: 5px; /* Adjust to position the badge correctly */
  right: 5px; /* Adjust to position the badge correctly */
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adds slight shadow for emphasis */
}

.edit-icon {
  width: 20px; /* Set the size of the edit icon */
  height: 20px;
}
.profile-text {
  margin-top: 10px;
  font-size: 14px;
  color: #333; /* Adjust text color and font-size */
  font-weight: bold;
  margin-left: 14px;
}
.EditProfile-Button{
  display: flex;
  justify-content: center;
  align-items: center;
}

.Edit-Button {
  display: flex;
  width: 18vw;
  height: 6vh;
  padding: 23px 14px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  background: var(--Colors-Green, #34c759);
  border-radius: 8px;
  border: none;
  color: white;
}
.EditProfile-maincontent-middle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.EditProfile-form1 {
  display: flex;
  flex-direction: column;
}
.EditProfile-form2 {
  display: flex;
  flex-direction: column;
}
.form1-label {
  color: var(--Black, #000);
  margin: 10px 0px;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}
.EditProfile-form1-input {
  /* width: 355px; */
  width: 23.13vw;
  height: 7vh;
  /* height: 50px; */
  padding: 23px 16px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #fff;
}
.EditProfile-form2-input {
  display: block;
  /* width: 355px; */
  width: 23.13vw;
  height: 7vh;
  /* height: 50px; */
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #fff;
}
.EditProfile-maincontent-bottom{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
}

.EditProfile-update {
  display: flex;
  justify-content: flex-end;
  margin-top: 5vh;
  margin-right: 7vw;
}
.EditProfile-update-button {
  display: flex;
  width: 18vw;
  height: 5vh;
  padding: 23px 14px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  background: var(--Colors-Green, #34c759);
  border-radius: 8px;
  border: none;
  color: white;
}
/* @media only screen and (max-width: 1024px) {
  
  .EditProfile-form1-input {
    
    width: 85.13vw;
    height: 7vh;
    
    padding: 23px 16px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
  }
  .EditProfile-form2-input {
    display: block;
    
    width: 85.13vw;
    height: 7vh;
    
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
  }
} */
@media only screen and (max-width: 600px) {
  .EditProfile-Container {
    display: flex;
    flex-direction: column;
  }

  .EditProfile-Container1 {
    display: none;
  }
  .EditProfile-Container2 {
    display: flex;
    flex-direction: column;
    width:100%;
    padding: 20px;
  }
  .EditProfile-maincontent {
    flex-direction: column;
    margin-bottom: 20px;
    width:100%;
    padding: 8px;
    margin-left: 0px;
  }
  .EditProfile-maincontent-top {
    flex-direction: column;
    
  }
  .Edit-top-first-half{
    display: none;
    width: 0%;
  }
  .Edit-top-second-half{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .Edit-Button {
    display: flex;
   width: 175px;
    height: 58px;
    padding: 23px 14px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    background: var(--Colors-Green, #34c759);
    border-radius: 8px;
    border: none;
    color: white;
  }
  .EditProfile-maincontent-middle{
    flex-direction: column;
    margin-top: 30px;
  }
  .EditProfile-maincontent-bottom{
    display: flex;
    justify-content: center;
  } 
  .EditProfile-form1-input {
    /* width: 355px; */
    width: 85.13vw;
    height: 7vh;
    /* height: 50px; */
    padding: 23px 16px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
  }
  .EditProfile-form2-input {
    display: block;
    /* width: 355px; */
    width: 85.13vw;
    height: 7vh;
    /* height: 50px; */
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
  }
  .EditProfile-update-button {
    display: flex;
    width: 300px;
    height: 50px;
    padding: 23px 14px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    background: var(--Colors-Green, #34c759);
    border-radius: 8px;
    border: none;
    color: white;
  }
}
/* @media only screen and (max-width: 768px) {
  .EditProfile-Container {
    display: flex;
    flex-direction: column;
  }

  .EditProfile-Container1 {
    display: none;
  }
  .EditProfile-Container2 {
    display: flex;
    flex-direction: column;
    width:100%;
    padding: 20px;
  }
  .EditProfile-maincontent {
    flex-direction: column;
    margin-bottom: 20px;
    width:100%;
    padding: 8px;
    margin-left: 0px;
  }
  .EditProfile-maincontent-top {
    flex-direction: column;
    
  }
  .Edit-top-first-half{
    display: none;
    width: 0%;
  }
  .Edit-top-second-half{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .Edit-Button {
    display: flex;
   width: 175px;
    height: 58px;
    padding: 23px 14px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    background: var(--Colors-Green, #34c759);
    border-radius: 8px;
    border: none;
    color: white;
  }
  .EditProfile-maincontent-middle{
    flex-direction: column;
    margin-top: 30px;
  }
  .EditProfile-maincontent-bottom{
    display: flex;
    justify-content: center;
  } 
} */
