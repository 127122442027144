.report-page {
    font-family: Inter;
    padding: 10px;
  }
  
  .report-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .details-container {
    display: flex;
    flex-direction: row;
    margin: 0px 60px 20px 60px;
    border: 2px solid black;
  }
  
  .farmer-details, .sample-info {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
  }
  .farmer-details{
    background-color: #61AB4F;
  }
  .sample-info{
    background-color: #26669E;
  }
  .farmer-details-block, .Sample-Information-block{
    margin: 10px;
    color: white;
  }
  
  .Report-table {
    margin: 0px 60px 20px 60px;
  }
  
  .Report-table-row {
    display: flex;
    justify-content: space-between;
  }
  
  .Report-table-col {
    display: flex;
    flex-direction: row;
    width: 30%;
    text-align: center;
    border: 2px solid #61AB4F;
    margin-bottom: 2px;
  }
  .Report-image-container{
    width:40%;
  }
  
  .Report-table-image {
    width: 100%;
    height: auto;
  }
  .Report-details-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:60%;
  }
  
  .Report-photo-section {
    display: flex;
    flex-direction: column;
    margin: 20px 60px 20px 60px;
  }
  
  .Report-photo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
  }
  
  .Report-photo-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 25%;
  }
  .Report-desc-image{
    width:100%;
  }

  .Report-Recommendation{
    display: flex;
    flex-direction: column;
    margin: 20px 60px 20px 60px;
    background-color: #D5D5D5;
    padding: 10px;

  }
  
  .report-footer {
    margin-top: 20px;
    text-align: center;
  }

  .TestReport-Btn-Div{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .Report-Retest-Btn{
    background-color: #61AB4F;
    padding: 10px;
    font-size: 16px;
    margin-right: 20px;
    border-radius: 10px;
    color: white;
  }
  
  .Report-Download-Btn{
    background-color: #61AB4F;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    color: white;
  }

  .Report-Recommendation {
    margin: 20px;
  }
  
  .Report-Recommendation h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .table-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .crop-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .crop-table th,
  .crop-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .crop-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  

  @media only screen and (max-width: 1024px) {
    .details-container{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0px;
      margin-bottom: 20px;
    }
    .farmer-details, .sample-info{
      width: 100%;
    }
    .Report-table{
      margin: 0px;
      margin-bottom: 20px;
    }
    .Report-table-col{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .Report-Recommendation{
      margin: 0px;
      margin-bottom: 20px;
    }
    .Report-photo-section{
      margin: 0px;
      margin-bottom: 20px;
    }
    .TestReport-Btn-Div{
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
    .report-page {
      font-family: Inter;
      padding: 10px;
      width: fit-content;
    }
    .details-container{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0px;
      margin-bottom: 20px;
    }
    .farmer-details, .sample-info{
      width: 100%;
    }
    .Report-table{
      margin: 0px;
      margin-bottom: 20px;
    }
    .Report-table-col{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .Report-Recommendation{
      margin: 0px;
      margin-bottom: 20px;
      width: fit-content;
    }
    .Report-photo-section{
      margin: 0px;
      margin-bottom: 20px;
    }
    .TestReport-Btn-Div{
      margin-top: 20px;
    }
  }