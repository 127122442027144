

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: "Arial", sans-serif;
  }
  
  .container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
  }
  
  /* Left Panel Styling */
  .left-panel-otp {
    flex: 1;
    background-image:url("../../assets/image/farmers-scheme-istock.svg") ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
  }
  .text-layout {
    display: flex;
    width: 35.85vw;
    /* height: 21vh; */
    padding: 2vw 4vh;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 47.562px;
    background: #fff;
  }
  
  .text-overlay {
    top: 10%;
    position: absolute;
    /* bottom: 42%; */
    left: 11%;
    color: white;
  }
  
  .text-overlay h1 {
    font-size: 2.5rem;
    color: #f1c515;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    background-color: white; /* White box */
    padding: 10px;
    border-radius: 10px;
  }
  
  .text-layout2 {
    display: flex;
    position: absolute;
    bottom: 10%;
    left: 11%;
    width: 33vw;
    height: 20vh;
    padding: 37px 47px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: rgba(255, 242, 242, 0.13);
    backdrop-filter: blur(50px);
  }
  
  .text-layout2-text {
    color: #f5f5f5;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
  
  /* Right Panel Styling */
  .right-panel-otp {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-color: #f2f2f2;
  }
  
  .signup-container {
    /* width: 80%;
    max-width: 400px; */
    text-align: center;
    background-color: #f2f2f2;
    padding: 20px;
    /* border-radius: 10px; */
  }
  .signup-header-container
  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 20px 30px; */
    background-color:  #f2f2f2;
    width: 44vw;
  }
  h2 {
    font-size: 2rem;
    color: #333;
  }
  
  p {
    color: #666;
    margin-bottom: 10px;
  }
  .logo-container {
    flex: 1;
  }
  
  .logo {
    width: 150px;
    height: auto;
  }
  
  .login-prompt-container {
    flex: 1;
    text-align: right;
  }
  
  .login-text {
    font-size: 1rem;
    color: #333;
  }
  
  .login-link {
    color: #28a745;
    text-decoration: none;
    font-weight: bold;
  }
  
  .login-link:hover {
    text-decoration: underline;
  }
  
  
  .social-signin {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    margin: 10px 0;
   
  }
  .googlelayout-otp{
    /* border-radius: 5px; */
    border-radius: 0.23vw;
    border: 1px solid #20DC49;
    background: #FFF;
    margin-right: 0.5vw;
  
  }
  .googlewrap-otp{
    display:flex;
    flex-direction: row;
    width: 7vw;
    height: 43px;
    flex-shrink: 0;
    justify-content: space-evenly;
   
  }
  .googlelogo{
    width: 22px;
    height: auto;
  }
  .googletext{
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px; /* 366.667% */
  }
  /* OTP Section */
  .otp-section {
    margin-top: 50px;
  }
  
  .otp-section h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .otp-section p {
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
  }
  
  .otp-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .otp-inputs input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .submit-btn-otp {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 19vw;
  }
  
  .submit-btn:hover {
    background-color: #218838;
  }
  
  .resend-link {
    font-size: 14px;
    color: #555;
    padding: 24px;
  }
  
  .resend-link a {
    color:   #34C759;
    text-decoration: none;
  }
  
 
  
  
 
  
  
  /* Responsive for Tablet */
  @media only screen and (max-width: 1024px){
    .container {
      display: flex;
      flex-direction:row;
      overflow: hidden;
    }
  
    .left-panel {
      /* display: flex;
      flex-direction: column; */
      flex: 1;
      background-image: url("../../assets/image/farmers-scheme-istock.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
  
    }
    
    .background-img {
      width: 100%;
      height: 100vh;
    }
    .text-layout {
      display: flex;
  
      padding: 2vw 4vh;
      flex-direction: column;
      align-items: flex-start;
      
      flex-shrink: 0;
      border-radius: 47.562px;
      background: #fff;
    }
    
    .text-overlay {
      color: white;
    }
    
    .text-overlay h1 {
      font-size: 2rem;
      color: #f1c515;
      font-family: "Inter", sans-serif;
      font-weight: 600;
      background-color: white; /* White box */
      padding: 10px;
      border-radius: 10px;
    }
    
    .text-layout2 {
      display: flex;
      padding: 37px 47px;
      height:fit-content ;
      flex-direction: column;
      align-items: flex-start;
      flex-shrink: 0;
      border-radius: 10px;
      background: rgba(255, 242, 242, 0.13);
      backdrop-filter: blur(50px);
    }
    
    .text-layout2-text {
      color: #f5f5f5;
      font-family: Inter;
      font-size: 1 rem;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    
    .right-panel {
    flex: 1;
    height:100vh;
  
    }
  
    
  
    .login-container {
      width: 90%;
      padding: 15px;
    }
  
    h2 {
      font-size: 1.5rem;
    }
    .googlelayout-otp{
      border-radius: 0.23vw;
      border: 1px solid #20DC49;
      background: #FFF;
      margin-right: 0.5vw;
      /* flex:1 1 0; */
    
    }
    .googlewrap-otp{
      display:flex;
      flex-direction: row;
      width: 16vw;
      height: 43px;
      flex-shrink: 0;
      justify-content: space-evenly;
     
    }
    
    
   
    .submit-btn-otp {
        background-color: #28a745;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        width: 35vw;
      }
    .terms {
      margin-top: 10px;
      font-size: 0.9rem;
      display: flex;
     
    }
    
    .terms input {
      flex: 1;
    }
    .termsText{
      display: flex;
      align-items: center;
    }
  }
  
  
  
  /* Responsive for Mobile */
  @media only screen and (max-width: 600px) {
    .container {
      display: flex;
      flex-direction: column;
      height: auto;
      overflow: hidden;
    }
    .background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
   }
    .left-panel {
      height: fit-content;
    }
  
    .right-panel {
      height: fit-content;
    }
  
    .text-overlay h1 {
      font-size: 2rem;
    }
  
    .text-overlay p {
      font-size: 1rem;
    }
  
    .text-layout {
      width: 90%; /* Reduce width for mobile */
      padding: 10px; 
      align-items: flex-start;
      align-self: stretch;
    }
  
    .login-container {
      width: 95%;
      padding: 10px;
    }
  
    h2 {
      font-size: 1.2rem;
    }
    
  .googlelayout-otp{
    border-radius: 0.23vw;
    border: 1px solid #20DC49;
    background: #FFF;
    margin-right: 0.5vw;
    /* flex:1 1 0; */
  
  }
  .googlewrap-otp{
    display:flex;
    flex-direction: row;
    width: 24vw;
    height: 43px;
    flex-shrink: 0;
    justify-content: space-evenly;
   
  }
  
  
  .facebooklayout{
    background: 0.23vw;
    border: 1px solid  #20DC49;
    background: #FFF;
    margin-left: 0.5vw;
    flex: 1 1 0;
  }
  .facebookwrap{
    display: flex;
    flex-direction: row;
    width: 24vw;
    height: 43px;
    flex-shrink: 0;
    justify-content: space-evenly;
  }
  
  
  
  
    .signup-form {
      display: flex;
      flex-direction: column;
      /* width: 80%; */
      max-width: 400px;
    }
    
    .signup-form label {
      text-align: left;
      margin: 10px 0 5px;
    }
    
    .signup-form input {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 10px;
      width: 45vw;
    }
    
    .submit-btn {
      background-color: #28a745;
      color: white;
      padding: 10px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      width: 45vw;
    }
    .terms {
      margin-top: 10px;
      font-size: 0.9rem;
      text-align: left;
      display: flex;
      align-items: center;
      width: 45vw;
    }
    
    .login-prompt-container {
      flex: 1;
      text-align: right;
    }
    
    .login-text {
      font-size: 1rem;
      color: #333;
    }
    
    .login-link {
      color: #28a745;
      text-decoration: none;
      font-weight: bold;
    }
  }
  
