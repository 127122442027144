.body {
  font-family: Inter;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.welcome-section {
  display: flex;
  padding: 23px;
  flex-direction: column;
}

.welcome-text {
  font-size: 22px;
  font-weight: bold;
}

.subtext {
  font-size: 16px;
  color: #777;
}

.headerButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.farm-dropdown {
  margin-right: 25px;
  color: white;
  background: #76b538;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  padding: 3px 7px 0px 7px;
  width: 120px;
  height: 40px;
}
.icon-button {
  margin-right: 25px;
  padding-bottom: 10px;
  cursor: pointer;
  color: #76b538;
}
/* .profile-image {
  width: 50px;
  height: 60px;
  border-radius: 50%;
  padding-bottom: 10px;
} */
.header-profile-image-wrapper {
  position: relative;
  width: 40px; /* Adjust the width according to your design */
  height: 40px; /* Ensure it's a square for the circular effect */
}

.header-profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Circular image */
  object-fit: cover; /* Ensures the image fits well within the circle */
  cursor: pointer; /* Add a pointer cursor to indicate it's clickable */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for a better look */
}
