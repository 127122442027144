/* General Styles */
body {
  font-family: Arial, sans-serif;
}

.dashboard-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: #f0f2f5;
}
.main-content-1 {
  display: flex;
  width: 5%;
}
/* Main Content Styles */
.main-content-2 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 10px 0 40px;
  width: 95%;
}
.main-content-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.main-content-col1 {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  width: 78%;
  padding: 10px;
}

.dashboard-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 15%;
}
.dashboard-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 32%;
  height: 100%;
  padding: 34px 24px;
  align-items: center;
  background-color: white;
  gap: 12%;
  border-radius: 5px;
  border: 1px solid rgba(90, 90, 90, 0.37);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.19);
}
.dashboard-Chart-card {
  display: flex;
  justify-content: center;
  width: 32%;
  height: 100%;
  align-items: center;
  gap: 40px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgba(90, 90, 90, 0.37);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.19);
}
.dashboard-middle {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.insight-left {
  width: 45%;
  height: 292px;
  margin-right: 15px;
}
.insight-left-container {
  width: 100%;
  height: 85%;
  overflow: auto;
  margin-right: 10px;
}

.insight-left-container::-webkit-scrollbar {
  width: 4px; /* Adjust width */
}

.insight-left-container::-webkit-scrollbar-track {
  background: #7d7d7d;
  border-radius: 10px;
}

.insight-left-container::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

.insight-left-container::-webkit-scrollbar-thumb:hover {
  background: black;
}
.insight-left-paragraph {
  margin: 0;
  display: flex;
  width: 98%;
  padding: 12px;
  align-items: center;
  background-color: white;
  gap: 5px;
  margin-bottom: 8px;
  border-radius: 5px;
  border: 0.85px solid rgba(90, 90, 90, 0.26);
}
.insight-right {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  border: 1px solid rgba(90, 90, 90, 0.26);
  width: 50%;
  height: 250px;
  background-color: white;
  margin-top: 50px;
}
.insight-right-image {
  width: 30%;
  display: flex;
  background-image: url("../../assets/images/morubhumi.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.insight-right-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.dashboard-bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.graph-heading {
  display: flex;
  flex-direction: row;
  font-size: "18px";
  font-weight: bold;
}
.graph-heading-dropdowns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.graph-year-dropdown {
  display: flex;
  width: 45%;
}
.graph-npk-dropdown {
  display: flex;
  width: 45%;
  margin-right: 10px;
}
.graph-year-dropdown select {
  width: 100%;
  padding: 10px 10px;
  padding-right: 20px;
  background: white;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 400;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.19);
}
.graph-npk-dropdown select {
  width: 100%;
  padding: 10px 10px;
  padding-right: 20px;
  background: white;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 400;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.19);
}
.chart-section {
  width: 100%; /* Adjust based on your design */
  margin: 20px 0;
  border: 0.85px solid rgba(90, 90, 90, 0.26);
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
}
.main-content-col2 {
  display: flex;
  flex-direction: column;
  width: 22%;
}

.dashboard-weather {
  border-radius: 12px;
  border: 0.862px solid #eaeaea;
  background: var(--Primary-100, #cbd2e2);
  width: 100%;
}
.weatherforecast {
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  margin: 10px;
  overflow-y: auto;
}
.soilanalysis {
  display: flex;
  border-radius: 8px;
  background: #fff;
  margin: 30px 10px 10px 10px;
  overflow-y: auto;
}
.soilanalysis::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .dashboard-middle {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: space-between;
    gap: 21px;
  }
  .insight-left {
    width: 100%;
    height: 250px;
    margin-right: 15px;
  }
  .insight-right {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    border: 1px solid rgba(90, 90, 90, 0.26);
    width: 100%;
    height: 250px;
    background-color: white;
    margin-top: 50px;
  }
}

/* Mobile View */
@media only screen and (max-width: 600px) {
  .dashboard-container {
    flex-direction: column;
  }
  .main-content-1 {
    display: none;
  }

  .main-content-2 {
    width: 100%;
    padding: 0 10px 0 10px;
  }

  .main-content-dashboard {
    flex-direction: column;
  }
  .main-content-col1 {
    width: 100%;
  }
  .dashboard-top {
    display: flex;
    flex-direction: column;
  }

  .dashboard-card {
    width: 100%; /* Full width on mobile */
    margin-bottom: 10px;
  }
  .dashboard-Chart-card {
    width: 100%;
    padding: 0px;
  }
  .dashboard-middle {
    display: flex;
    flex-direction: column;
  }

  .insight-left {
    width: 100%; /* Full width on mobile */
    margin-right: 0;
    margin-bottom: 10px;
  }

  .insight-left-container {
    width: 100%;
    height: 200px;
  }
  .insight-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 450px;
  }
  .insight-right-image {
    width: 100%;
    height: 40%;
    margin-right: 0;
  }
  .insight-right-container {
    width: 100%;
    height: 60%;
  }
  .dashboard-bottom {
    flex-direction: column;
  }
  .graph-heading {
    width: 100%;
    margin-bottom: 10px;
    justify-content: flex-start;
  }
  .graph-heading-dropdowns {
    width: 100%;
    justify-content: space-between;
  }

  .forecast-card-CS {
    font-size: 2.5vw;
  }

  .iconCS {
    font-size: 5vw;
    color: orange;
  }

  .main-content-col2 {
    width: 100%;
  }

  .fs-small {
    width: 5vw;
    height: 5vw;
  }
  .line {
    margin-top: calc(2.5vw + 10px);
  }
}
