.Container{
    width: 100%;
    height: 100%;
    display: flex;
    }
    .LeftHalf{
        flex:1;
        display: flex;
        height: 100vh;
        background-image:url("../../assets/image/farmers-scheme-istock.svg") ;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    
    
    }
    .TextOverlay{
        top: 10%;
        position: absolute;
        left: 5%;
        background: #fff;
        width: 35vw;
        height: auto;
        border-radius: 30px;
        padding: 20px 20px;
    }
    .TextOverlayHeader{
         position: relative;
        font-size: 2.5vw;
        color: #f1c515;
        font-family: "Inter", sans-serif;
        font-weight: 550;
        padding: 10px ;
        border-radius: 10px;


    }
    .TextOverlay2{
    position: absolute;
    bottom: 20%;
    left: 5%;
    width: 24vw;
    height: auto;
    padding: 30px 20px;
    border-radius: 10px;
    background: rgba(255, 242, 242, 0.13);
    backdrop-filter: blur(50px);
    }
    .TextOverlayPara{
    position: relative;
    color: #f5f5f5;
    font-family: Inter;
    font-size: 1vw;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    }
    .RightHalf{
        flex:1;
        display: flex;
        flex-direction: column;
      
        padding-top: 6vw;
    }
    .Welcome{
        display:flex;
        flex-direction: column;
        align-items: center;

    }
    .SoilTechLogo{
        display: flex;  
         width: 10vw;
         justify-content: center;
        
     }
     .WelcomeText{
        display: flex;
        justify-content: center;
        font-size: 1.5vw;
        font-weight: 600;
        font-style: normal;
        font-family: Inter;
        color: #000;
        opacity: 0.6;
    }
    .WelcomeText2{
        display: flex;
        justify-content: center;
        font-size: 1.5vw;
        font-weight: 600;
        font-style: normal;
        font-family: Inter;
        color: #000;
        opacity: 0.6;
        padding-top: 3vw;
        padding-bottom: 2vw;
    }
    .Form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50vw;
      }
      
      .InputField {
        display: flex;
        flex-direction: column;
        margin-bottom: 1vw;
        width: 22vw;
      }
      
      .InputField label {
        font-size: 1vw;
        margin-bottom: 5px;
        color: #374151;
      }
      
      .InputField input {
        padding: 10px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        font-size: 1vw;
      }
      
      .SubmitButton {
        width: 22vw;
        padding: 10px;
        background-color: #34C759;
        color: #000;
        border: none;
        border-radius: 5px;
        font-size: 1vw;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      
      .SubmitButton:hover {
        background-color: #059669;
      }