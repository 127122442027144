body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}
.Recommendation-container {
  display: flex;

  background-color: #f0f2f5;
}
.Recommendation-container1 {
  display: flex;
}
.Recommendation-container2 {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  /* margin-left: 5vw; */

  background: var(--Background-Base, #f0f2f5);
}

.Recommendation-maincontent {
  padding: 20px;
}

.Recommendation-top {
  background-color: #f9f9f9;
  /* padding: 20px; */
  margin-bottom: 20px;
}

.Recommendation-plantedcrop,
.Recommendation-crop {
  margin-bottom: 20px;
}

.crop-list {
  display: flex;
  flex-wrap: wrap;
  /* width: max-content; */
}

.recommendation-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../../assets/image/recommendationbackground.png");
  background-position-y: center;
  background-size: cover;
  padding: 15px;
  border-radius: 5px;
}

.date-weather {
  font-size: 0.8rem;
  margin: 0;
  color: #fff;
  height: 162px;
}
.date-weather-h2 {
  font-size: 2rem;
  margin: 0;
  color: #fff;
}
.date-weather-p {
  font-size: 0.8rem;
  margin: 0;
  color: #fff;
}
.crop-stages-info {
  display: flex;
  flex-direction: row;
  border-radius: 1.25rem;
  width: 62vw;
  background: var(--Card-White, rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(8px);

  flex-shrink: 0;
}

.crop-stages div {
  margin-bottom: 5px;
}

.info {
  flex: 1;
}

.info p {
  font-size: 0.7rem;
  color: #fff;
  margin-top: 10px;
  max-width: 300px;
}

.crop-card {
  /* width: 250px;
  margin: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center; */
  width: 19vw;
  margin: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Image styles */
.crop-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}
.Recommendation-month-season {
  display: flex;
  border-bottom: dashed rgba(125, 125, 125, 0.3);
}

.Recommendation-month {
  padding: 0 2%;
  display: flex;
  flex: 1;
  font-size: 0.8em;
  /* margin-left: 0.6em; */
  align-items: center;
  justify-content: center;
}
.Recommendation-season {
  padding: 0 2%;
  display: flex;
  flex: 1;
  font-size: 0.8em;
  /* margin-left: 3rem; */
  justify-content: center;
  align-items: center;
}

/* No bullet points (dots) in recommended section */
.no-dots p {
  margin: 5px 0;
}

/* Dosage details styles */
.dosage-details p {
  margin: 5px 0;
  text-align: left;
}
.dosage-details {
  display: flex;
}
.dosage-details-left {
  flex: 1;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.dosage-details-right {
  flex: 1;
  display: flex;
  padding: 1vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.dosage-details-left-p {
  font-size: 0.8em;
}

@media only screen and (max-width: 1024px) {

  .crop-list-container {
    max-width: 78vw;
    overflow: auto;
  }
  .crop-stages-info {
    display: flex;
    flex-direction: row;
    border-radius: 1.25rem;
    width: 62vw;
    background: var(--Card-White, rgba(255, 255, 255, 0.1));
    backdrop-filter: blur(8px);
    flex-shrink: 0;
  }
  .crop-card {
    width: 31vw;
    margin: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  .crop-list {
    display: flex;
    width: max-content;
  }
}

@media only screen and (max-width: 600px) {
  .Recommendation-container1 {
    display: none;
  }
  .Recommendation-container2 {
    margin-left: 0;
    padding: 10px;
  }
  .crop-stages-info {
    display: flex;
    flex-direction: column;
    background: none;
    backdrop-filter: none;
    margin-left: 9vw;
  }
  .recommendation-top {
    display: flex;
    flex-direction: column;
  }
  .recommendation-top-mobile {
    display: flex;
    flex-direction: column;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    border: 1px solid rgba(90, 90, 90, 0.26);
    background: #fff;
  }
  .info p {
    font-size: 0.9rem;
    color: var(--Neutral-600, #4b4b4b);
    margin-top: 10px;
    max-width: 300px;
  }

  .crop-list-container {
    max-width: 78vw;
  }

  .crop-list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .crop-card {
    width:75vw;
  }
}

/* @media only screen and (max-width: 768px) {
  .crop-card {
    width: 31vw;
    margin: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  .crop-list {
    display: flex;
    width: max-content;
  }
}

@media only screen and (max-width: 600px) {
  .Recommendation-container1 {
    display: none;
  }
  .Recommendation-container2 {
    margin-left: 0;
    padding: 10px;
  }
  .crop-stages-info {
    display: flex;
    flex-direction: column;
    background: none;
    backdrop-filter: none;
    margin-left: 9vw;
  }
  .recommendation-top {
    display: flex;
    flex-direction: column;
  }
  .recommendation-top-mobile {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 0.5rem 0.5rem;
    border: 1px solid rgba(90, 90, 90, 0.26);
    background: #fff;
  }
  .info p {
    font-size: 0.9rem;
    color: var(--Neutral-600, #4b4b4b);
    margin-top: 10px;
    max-width: 300px;
  }
  .crop-card {
    width: 300px;
  }
} */
