
  
  body {
    font-family: "Arial", sans-serif;
  }
  
  .container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
  }
  
  /* Right Panel Styling */
  .right-panel-forgot {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    padding: 30px 40px;
  }
  .forgot-header-container
  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 20px 30px; */
    width: 100%;
  }
  .ForgotPassword-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .signup-container {
    /* width: 80%;
    max-width: 400px; */
    text-align: center;
    background-color: #f2f2f2;
    padding: 20px;
    /* border-radius: 10px; */
  }
  
  
  .sendotp-btn {
    background-color: #28a745;
    color: white;
    padding: 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
  }

  .login-link{
    border: none;
    font-size: 16px;
  }
  
  /* Responsive for Tablet */
  @media (max-width: 900px) {
    .container {
      display: flex;
      flex-direction:row;
      overflow: hidden;
      min-height: 100vh;
    }
  
    
    .right-panel-forgot {
      width: 100%;
      flex: 1;
  
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    
    
   
    .submit-btn {
      background-color: #28a745;
      color: white;
      padding: 10px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      width: 40vw;
    }
  }
  
  
  
  /* Responsive for Mobile */
  @media (max-width: 576px) {
    .container {
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow: hidden;
    }

    .forgot-prompt-container{
        margin-top: 4vh;
    }

    .ForgotPassword-form{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    
 
  
  
  
    
    
    
  }
  