/* Sidebar Styles */
.sidebar {
  width: 250px;
  /* height: 100vh; */
  height: -webkit-fill-available;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  transition: width 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

.sidebar.collapsed {
  width: 80px;
}

.fullSidebar {
  position: fixed;

}

.top-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: flex-start;
}

.logo-img {
  width: 100%;
}

.toggle-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.menu {
  margin-top: 20px;
}

.menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
  padding: 10px 10px;
  color: #76B538;
  text-decoration: none;
  transition: color 0.2s ease;
}

.menu-item:hover {
  background-color: #76B538;
  color: white;
}

.menu-item.active {
  background-color: #ffffff;
  color: #76B538;
  border-bottom: 2px solid #76B538;
}

.icon {
  font-size: 1.5rem;
}

.menu-item span {
  font-size: 1rem;
}

.sidebar.collapsed .menu-item span {
  display: none;  /* Hide text in collapsed state */
}

.upgrade-section {
  margin-top: auto;
}

.upgrade-button {
  background-color: #f5f5f5;
  color: #76B538;
  padding: 10px;
  border: none;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.buy-now-button {
  background-color: #76B538;
  color: white;
  padding: 10px;
  border: none;
  width: 100%;
  cursor: pointer;
}

/* Responsive design for mobile */
@media screen and (max-width: 600px) {
  .sidebar {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #ffffff;
    width: 50vw;
    height: 75vh;
    z-index: 1000;
    transition: width 0.3s ease, transform 0.3s ease;
    top: 0;
    left: 0;
  }

  .menu{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  .menu-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .menu-item span {
    display: flex;  /* Hide text in mobile view */
  }

  .toggle-btn {
    position: absolute; /* Make sure the button is visible */
    top: 10px;
  }

  .upgrade-button,
  .buy-now-button {
    font-size: 0.9rem;
    padding: 8px;
  }
}

/* Further adjustments for very small screens */

/* for mobile */
.mobile-fullSidebar {
  width: 50%; /* Full width for mobile */
}

.mobile-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.close-btn {
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  color: #333;
}












/* .sidebar {
  width: 250px;
  background: var(--Grey-100, #fff); */
  /* box-shadow: 0px 0px 2.566px 0px rgba(24, 20, 31, 0.06), 0px 20.531px 46.195px 0px rgba(24, 20, 31, 0.15); */
  /* color: white; */
  /* transition: width 0.3s ease;
  min-height: 100vh;
  position: absolute; */
/* } */

/* .sidebar.collapsed {
  width: 80px;
} */
/* .Togglewarp {
  display: flex;
  flex-direction: row;
} */

/* .sidebar ul {
  list-style: none;
  padding: 0;
} */

/* .sidebar ul li {
  padding: 15px 20px;
  display: flex;
  align-items: center;
} */

/* .sidebar ul li span {
  margin-left: 10px;
} */

/* .toggle-btn { */
  /* background: var(--Grey-100); */
  /* box-shadow: 0px 0px 2.566px 0px rgba(24, 20, 31, 0.06), 0px 20.531px 46.195px 0px rgba(24, 20, 31, 0.15); */
  /* border: none;
  padding: 15px;
  cursor: pointer;
  margin-left: 1.5vh;
} */

/* .toggle-btn:hover {
  border-radius: 6.416px;
  background: var(--Primary, #76b538);
} */
/* .SoilImage { */
  /* width: 142px; */
  /* width: 8vw;
  height: auto;
} */
/* 
.dashboardicon:hover {
  color: #ffffff;
} */
/* .IconWrapper {
  display: flex;
  align-items: center;
} */
/* .Prolayout {
  display: inline-flex;
  padding: 10.283px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.283px;
  border-radius: 12.395px;
  background: var(--Primary, #76b538);
  margin-top: 9vh;
  margin-left: 1vh;
} */
/* .Probutton {
  display: flex;
  padding: 10.283px 44.217px 10.283px 45.246px;
  justify-content: center;
  align-items: flex-end;
  gap: 10.283px;
  border-radius: 20.566px;
  background: #fff;
  box-shadow: 2.057px 4.113px 4.113px 0px rgba(79, 42, 234, 0.17);
  */
/* } */
/* .Protext {
  color: var(--Neutral-100, #f5f5f5);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  width: 188.181px;
} */

/* .IconWrapper:hover{
    background: var(--Primary, #76B538);
  } */
