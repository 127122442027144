.weather-forecast-container {
    display: flex;
    flex-direction: column;
  }
  .weather-forecast-heading{
    display: flex;
    font-weight: bold;
    padding: 10px 5px;
  }
  
  .forecast-cards {
    display: flex;
    flex-direction: row; /* Display cards in a row */ /* Ensure all cards have the same height */
    gap:1.5em; /* Adjust gap between cards */
    overflow-x: auto; /* Allow horizontal scrolling */
    padding: 10px 5px;
  }

  .forecast-cards::-webkit-scrollbar {
    display: none;
  }
  
  .forecast-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 1.875rem; /* More rounded */
    padding: 0.5rem; /* Inner padding */
    width: 100px; 
    border: 1px solid rgba(255, 255, 255, 0.2); /* Border with relative units */
    background: rgba(18, 145, 114, 0.64);
    box-shadow:  1px 0px 0px rgba(255, 255, 255, 0.25) inset, 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  
  .forecast-card.current {
    background: var(--Primary, #76B538);;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 1.875rem; /* More rounded */
    padding: 0.5rem; /* Inner padding */
    width: 15rem; 
    border: 1px solid rgba(255, 255, 255, 0.2); /* Border with relative units */
    box-shadow:  1px 0px 0px rgba(255, 255, 255, 0.25) inset, 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  
  .time {
    font-size: 13px; /* Relative font size */
    color: white;
    text-align: center;
  }
  
  .forecast-card .icon {
    font-size: 2em;
    color: white;
  }
  
  .forecast-card .rain-chance {
    font-size: 0.7em;
    color: white;
    text-align: center;
  }
  
  .forecast-card .temp {
    font-size: 1em;
    color: white;
    text-align: center;
  }
  
  .forecast-card:hover {
    transform: scale(1.05); /* Slight enlargement on hover */
  }
  