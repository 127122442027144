/* App.css */

body {
  font-family: "Arial", sans-serif;
  background-color: #f5f5f5;
}

.container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

/* Left column (Image and Text) */
.left-column {
  flex: 1;
  display: flex;
  position: relative;
  background-image:url("../../assets/image/farmers-scheme-istock.svg") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: flex-start;
}

.image-section {
  height: 100%;
  width: 100%;
  background-image: url("../../assets/image/farmers-scheme-istock.svg");
  background-position: center;
  background-size: cover;
}

.image-container {
  /* height: 100%;
  width: 100%;
  overflow: hidden; */
  /* background-image: url("../../assets/image/farmers-scheme-istock.svg");
  background-position: center;
  background-size: cover; */
}

.background-image {
  width: 100%;
  object-fit: cover;
}

.heading {
  position: absolute;
  width: 75%;
  top: 20%;
  left: 10%;
  color: #f1c515;
  font-size: 2.5rem;
  font-weight: bold;
  background: rgb(255, 255, 255);
  padding: 10px 20px;
  border-radius: 10px;
}

.sub-text {
  position: absolute;
  width: 75%;
  bottom: 10%;
  left: 10%;
  background: linear-gradient(to right, #a1a1a1, #d4b483);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0.9;
  padding: 15px;
  border-radius: 10px;
  font-size: 1rem;
  max-width: 80%;
}

/* Right column (Login Form) */
.right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 30px 40px;
  background-color: #f2f2f2;
}
.login-header-container
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 20px 30px; */
  background-color:  #f2f2f2;
  width: 48vw;
}

.form-section {
  width: 80%;
  max-width: 400px;
  text-align: center;
  padding-top: 3vw;
}

.logo {
  width: 100%;
  margin-bottom: 20px;
}

.welcome-text {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 10px;
}


.login-button {
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #218838;
}
.wrapswitch{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2vh;
  
 
}
.login-checkboxtext{
  /* margin-top: 10px; */
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.login-text1{
 color: var(--Neutral-700, #4B4B4B);
 font-feature-settings: 'liga' off, 'clig' off;
 font-family: Inter; 
 font-size: 12px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px; /* 166.667% */
 letter-spacing: 0.3px;
 flex: 1;
 padding-left: 1vh;

}
.login-text2{
text-align: right;
font-family: Inter;
font-size: var(--Title-Small-Size, 14px);
font-style: normal;
font-weight: 500;
line-height: var(--Title-Small-Line-Height, 20px); /* 142.857% */
letter-spacing: var(--Title-Small-Tracking, 0.1px);
}
/* New Class for Layout */


/* The switch - the box around the slider */
.switch {
position: relative;
display: inline-block;
width: 42px;
height: 19px;
}

/* Hide default HTML checkbox */
.switch input {
opacity: 0;
width: 0;
height: 0;
}

/* The slider */
.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 15px;
width: 14px;
left: 3px;
bottom: 2px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
/* border-radius: 12px; */
background: var(--Primary, #76B538);

}

input:focus + .slider {
box-shadow: 1px 1px 2px -1px rgba(51, 51, 51, 0.30);
}

input:checked + .slider:before {
-webkit-transform: translateX(23px);
-ms-transform: translateX(23px);
transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
border-radius: 32px;
}

.slider.round:before {
border-radius: 45%;
}







@media only screen and (max-width: 600px){
  .image-section {
    height: 30vh;
    width: 100%;
    background-image: url("../../assets/image/farmers-scheme-istock.svg");
    background-position: center;
    background-size: cover;
  }
  .image-container {
    height: 30vh;
    width: 100%;
    overflow: hidden;
  }

  .sub-text {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .left-column {
    display: flex;
    flex: 0;
    flex-direction: column;
  }

  .right-column {
    align-items: center;
  }

  .heading {
    font-size: 1.8rem;
  }

  .sub-text {
    font-size: 0.9rem;
  }
  .welcome-text-mobile {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  .background-img-login{
    height: 100%;
   
    position: absolute;
    z-index: -1;
  }
  .text-overlay-login{
    display: flex;
    z-index: 1;
    width: 100%;
    justify-content: center;
  }
  .text-layout-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    width: 75%;
    padding: 10px;
    margin-top: 35px;
    border-radius: 10px;
  }
  .text-login-header{
    color: orange;
    font-size: 24px;

  }
  .login-paragraph{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .social-signin{
    margin-bottom: 20px;
  }
}
