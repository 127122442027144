/* Header.css */
.header-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    background-color:  #f2f2f2;
  
  }
  
  .logo-container {
    flex: 1;
  }
  
  .logo {
    width: 150px;
    height: auto;
  }
  
  .login-prompt-container {
    flex: 1;
    text-align: right;
  }
  
  .login-text {
    font-size: 1rem;
    color: #333;
  }
  
  .login-link {
    color: #28a745;
    text-decoration: none;
    font-weight: bold;
  }
  
  .login-link:hover {
    text-decoration: underline;
  }
  
  /* Responsive for Tablet */
  @media (max-width: 900px) {
    .header-container {
      flex-direction: row;
      justify-content: flex-start;
    }
  
    .logo {
      width: 120px;
    }
  
    .login-text {
      font-size: 0.9rem;
    }
  }
  
  /* Responsive for Mobile */
  @media (max-width: 576px) {
    .header-container {
      flex-direction: column;
      align-items: center;
    }
  
    .logo {
      width: 100px;
      margin-bottom: 10px;
    }
  
    .login-text {
      font-size: 0.9rem;
      text-align: center;
    }
  }
  