body {
  font-family: Arial, sans-serif;
}
.Soiltest-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: #F0F2F5;
}
.Soiltest-content-1{
  display: flex;
  width: 5%;
}
.Soiltest-content-2 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 10px 0 44px;
  width: 95%;
}
.Soiltest-top {
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  padding-left: 2.3vw;
}

.Soiltest-information {
  width: 100%;
  display: flex;
  margin-top: 10px;
}
.details-form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.details-form-1 {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.details1-label {
  color: var(--Black, #000);
  margin-top: 6px;
  margin-bottom: 10px  ;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px; /* 150% */

}
.details1-input {
  /* width: 355px; */
  width: 24vw;
  /* height: 50px; */
  height: 6.17vh;
  padding: 23px 16px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #fff;
}
.details-form-2 {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.details2-input {
  display: block;
  width: 24vw;
  /* height: 50px; */
  height: 6.17vh;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #fff;
}
.Soiltest-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.uploaded-img{
  width: 285px;
  height: 303px;
  object-fit: cover;
}
.Soiltest-upload-image {
  display: flex;
  flex-direction: column;
  margin-top: 3vh;
  padding: 14px 12px;
  align-items: center;
  border-radius: 14px;
  background: #fff;
}

.upload-1{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upload-2{
  display: flex;
  width: 75%;
  flex: 1;
  position: relative;
}
.upload-circle {
  width: 60%;
  border-radius: 50%;
  position: relative;
  margin: 0 auto 2vh auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backgroundCircle{
  width: 100%;
  flex-shrink: 0;
}
.upload-cards {
  width: 100%;
  height: 50px;
  position: absolute;
  /* top: 50%; */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 13.5px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: linear-gradient(357deg, #E4E7EC 5.78%, #F9FAFB 92.98%);
}


.upload-cards:nth-child(2) {
  top: 35%;
  transform: translateY(-50%);
  background-color: #fff;
}

.upload-cards:nth-child(3) {
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.plus-sign {
  position: absolute;
  width: fit-content;
  height: fit-content;
  background: #76B538;
  border-radius: 100%;
  padding: 3%;
  margin: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.green-circle {
  height: 80%;
  aspect-ratio: 1;
  background: #76B538;
  border-radius: 100%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5%;
  margin-right: 5%;
}
.green-lines {
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 100%;
  justify-content: space-evenly;
  align-items: start;
}

.green-line {
  height: 20%;
  
  background-color: #9BD59B;
  border-radius: 500px;
}
.upload-capture{
  display: flex;
  /* width: 191px; */
  width: 24.43vw;
  height: 6.74vh;
  /* height: 33px; */
  padding: 10px 14px;
  background: var(--Colors-Green, #34c759);
  font-family: Inter;
  font-size: 18px;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  justify-content: center;
  color: white;
}
.upload-edit{
  display: flex;
  /* width: 355px; */
  width: 24vw;
  height: 57.75px;
  padding: 23px 123px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-top: 3vh;
  border-radius: 8px;
  border: 1px solid var(--Colors-Green, #34c759);
}

.form-submit-div{
  display: flex;
  justify-content: center;
}
.form-submit-btn {
  display: flex;
  /* width: 355px; */
  width: 24vw;
  height: 57.75px;
  padding: 23px 147px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  flex-shrink: 0;
  margin-top: 3vh;
  background: var(--Colors-Green, #34c759);
  border-radius: 8px;
  border: none;
  color: white;
}
.form-submit-btn:disabled{
  background-color: white;
  color: #34c759;
  border: 1px solid black;
  cursor: not-allowed;
}

.ModalHeader{
  color: var(--Primary, #76B538);
text-align: center;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 48px; 
text-transform: uppercase;
}
.ModalContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Space between video and images */
  align-items: flex-start;
  width: 100%;
}

.ModalWindowLeft {
  flex: 2;
  padding:20px;
}

.SoilImage1Container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; 
  gap: 10px; 

} 
.SoilImage2Container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; 
  gap: 10px; 
}

.Csoil{
  height: 198px;

}
 .IncorrectSoil{
  height: 198px;
 }

  .LeafSoil{
    height: 177px;
  }
  .GrassSoil {
    height: 177px;
}

.ModalWindowRight {
  flex: 1;
  padding: 20px;
}

.SoilVideo { 
  max-height: 515px; 
  object-fit: cover;
}
.ModalText{
  padding: 1vh;
}
.SoilImageWrapper {
  position: relative;
  /* display: inline-block; */

}
.RightCircle{
  position: absolute;
  top: 37%; /* Adjust this to position the circle higher or lower */
  left: 47%;
  transform: translateX(-50%);
  width: 60px; /* Adjust the size of the circle */
  height: 30px;

}
 .WrongCircle {
  position: absolute;
  top: 37%; /* Adjust this to position the circle higher or lower */
  left: 47%;
  transform: translateX(-50%);
  width: 60px; /* Adjust the size of the circle */
  height: 30px;
}
@media only screen and (max-width: 1366px){
  .Csoil{
    height: 96px;
  
  }
   .IncorrectSoil{
    height: 96px;
   }
  
    .LeafSoil{
      height: 95px;
    }
    .GrassSoil {
      height: 95px;
  }
  .SoilVideo { 
    max-height: 389px; 
    object-fit: cover;
  }
}

@media only screen and (max-width: 600px) {
  .Soiltest-container {
    display: flex;
    flex-direction: column;
  }
  .Soiltest-content-1{
    display: none;
  }
  .Soiltest-content-2{
    display: flex;
    flex-direction: column;
    width:100%;
    padding: 20px;
  }
  .Soiltest-information{
    flex-direction: column;
  }
  .details-form{
    flex-direction: column;
    justify-content: center;
  }
  
  .details1-input {
    width: 100%;
    height: 50px;
    padding: 23px 16px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
  }
  .details2-input {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 15px 16px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
  }
  .Soiltest-upload{
    display: flex;
    width: 100%;
  }
  .uploaded-img{
    display: flex;
    width: 100%
  }
  .upload-edit{
    display: flex;
    width: 100%;
  }
  .form-submit-btn{
    display: flex;
    width:100%;
    height:50px
  }
  .Modal-Container{
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  .ModalContent{
    display: flex;
    flex-direction: column;
  }
  .form-submit-div{
    display: flex;
    justify-content: center;
  }
}
