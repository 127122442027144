.terms_signup {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .terms_signup_input {
    margin-right: 10px;
  }
  .termsText{
    font-size: 1rem;
    margin-top: 8px;
  }