
.column_weather_forecast {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.day-heading {
    font-size: 1.5vw;
}

.upper-container, .lower-container {
    padding: 8px;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.lower-container {
    color: white;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .day-heading {
        font-size: 3vw; /* Increase font size for better visibility on mobile */
    }
    .upper-container{
        display: flex;
        flex-direction: column;
    }
    .upper-container, .lower-container {
        padding: 10px; /* Reduce padding on smaller screens */
        width: 100%;   /* Ensure containers take full width */
        align-items: center; /* Center content on mobile */
    }
}