/* .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 1180px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  } */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 32px 59px 41px 59px; /* Matches the new padding */
    border-radius: 8px;
    max-width: 1306px; /* Set the modal's width */
    /* width: 100%; */
    width: 70vw;
    max-height: 90vh;
    overflow-y: auto; /* Ensure the modal takes full width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
  }
  
  .modal-close-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .modal-close-btn:hover {
    background-color: #0056b3;
  }
  @media only screen and (max-width: 1366px) {
    .modal-content {
      background-color: #fff;
      padding: 32px 59px 41px 59px; 
      border-radius: 8px;
      max-width: 1306px; 
      
      width: 70vw;
      max-height: 90vh;
      overflow-y: auto; 
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
    }
    
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .modal-button{
      display: flex;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 600px) {
    .modal-overlay{
      width: 367px;
      height: 968px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 37px;
    }
    .modal-button{
      display: flex;
      justify-content: center;
    }
  }
  