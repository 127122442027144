.googlelayout{
    /* border-radius: 5px; */
    border-radius: 0.23vw;
    background: #FFF;
    margin-right: 0.5vw;
  }

  .googlewrap{
    display:flex;
    flex-direction: row;
    height: 40px;
    justify-content: space-evenly;
  }

  @media only screen and (max-width: 576px)  {
    
  }