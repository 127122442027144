.Container{
    width: 100%;
    height: 100%;
    display: flex;
    }
    .LeftHalf{
        flex:1;
        display: flex;
        height: 100vh;
        background-image: url('../../assets/image/MemberPhoto.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    
    
    }
    .TextOverlay{
        top: 10%;
        position: absolute;
        left: 5%;
        background: #fff;
        width: 35vw;
        height: auto;
        border-radius: 30px;
        padding: 20px 20px;
    }
    .TextOverlayHeader{
         position: relative;
        font-size: 2.5vw;
        color: #f1c515;
        font-family: "Inter", sans-serif;
        font-weight: 550;
        padding: 10px ;
        border-radius: 10px;


    }
    .TextOverlay2{
    position: absolute;
    bottom: 20%;
    left: 5%;
    width: 24vw;
    height: auto;
    padding: 30px 20px;
    border-radius: 10px;
    background: rgba(255, 242, 242, 0.13);
    backdrop-filter: blur(50px);
    }
    .TextOverlayPara{
    position: relative;
    color: #f5f5f5;
    font-family: Inter;
    font-size: 1vw;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    }
    .RightHalf{
        flex:1;
        display: flex;
        flex-direction: column;
        padding-top: 6vw;
       
    }
    .Welcome{
        display:flex;
        flex-direction: column;
        align-items: center;

    }
    .WelcomeText{
        display: flex;
        justify-content: center;
        font-size: 1.5vw;
        font-weight: 600;
        font-style: normal;
        font-family: Inter;
        color: #000;
        opacity: 0.6;

       
    }
    .Welcomepara{
        display: flex;
        color: #000;
        font-size: 1.2vw;
        font-weight: 500;
        font-style: normal;
        font-family: Inter;
        padding: 2vw 5vw 1vw 5vw;
    }
    .Welcomepara2{
        display: flex;
        color: #000;
        font-size: 1.2vw;
        font-weight: 500;
        font-style: normal;
        font-family: Inter;
        padding: 1vw 5vw 1vw 5vw;
    }
    .SoilTechLogo{
        display: flex;  
         width: 10vw;
         justify-content: center;
        
     }
     .ButtonClass{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1vw;
        padding-top: 4vw;
     }
     .MemberButton{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18vw;
        height: 3vw;
        cursor: pointer;
        background: var(--Colors-Green, #34C759);
        border:#34C759;
        border-radius: 0.4vw ;
        font-size: 1vw;
        
     }
     .EndUserButton{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18vw;
        height: 3vw;
        cursor: pointer;
         background: var(--Colors-Green, #34C759); 
         border:#34C759;
         border-radius: 0.4vw ;
         font-size: 1vw;
     }
 

   