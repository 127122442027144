.left-panel-signup {
    width: 45%;
    background-image:url("../../assets/image/farmers-scheme-istock.svg") ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .text-overlay-signup {
    top: 10%;
    position: absolute;
    left: 10%;
    background: #fff;
    width: 80%;
    border-radius: 30px;
    padding: 20px 20px;
  }
  
  .text-overlay-signup-header {
    font-size: 2.5rem;
    color: #f1c515;
    font-family: "Inter", sans-serif;
    font-weight: 550;
    padding: 10px ;
    border-radius: 10px;
  }
  
  .text-overlay2-signup {
    position: absolute;
    bottom: 20%;
    left: 10%;
    width: 60%;
    padding: 30px 20px;
    border-radius: 10px;
    background: rgba(255, 242, 242, 0.13);
    backdrop-filter: blur(50px);
  }
  
  .text-layout2-text-signup {
    color: #f5f5f5;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  @media only screen and (max-width: 576px){
    .left-panel-signup {
        width: 100%;
        background-image:url("../../assets/image/farmers-scheme-istock.svg") ;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
      }
    
      .text-overlay-signup-mobile {
        top: 10%;
        position: absolute;
        left: 10%;
        background: #fff;
        height: 70%;
        width: 80%;
        padding: 20px;
        border-radius: 10px;
      }
    
      .text-layout-signup-mobile{
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .text-signup-header{
        font-size: 6vw;
        color: #f1c515; 
      }
  }